import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select, { components } from "react-select"; 
import TooltipLabel from "./TooltipLabel/TooltipLabel";
import { darkThemeReactSelectStyles } from "./MuiThemedComponents";
import { useSelector } from "react-redux";

const SelectDropDown = ({onChange, options, placeholder, className, value, styles, ...props}) => {
  const user = useSelector((state) => state.user);

  const formatOptionLabel = (option) => (
    <TooltipLabel text={option?.label}/>
  );

  const selectStyle = user?.themePreference === 'dark' ? darkThemeReactSelectStyles : {};

    return (
      <Select  onChange={(val) => { onChange && onChange(val) }}
        options={options}
        classNamePrefix={`${props?.required ? "required " : "" } select-dropdown`}
        placeholder={placeholder || "Select"}
        components={{ Control }}
        formatOptionLabel={formatOptionLabel}
        isSearchable={true}
      required={props?.required}
        isLoading={props?.isLoading}
        menuPortalTarget={props?.menuPortalTarget}
        className={`service_filter ${className}`} value={value} 
        styles={{...styles, ...selectStyle}}
        {...props} 
    
        /> 
    )
   
}

const Control = ({ children, ...props }) => {
  const { hideFilterIcon } = props.selectProps;
  const user = useSelector(state => state?.user)

  return (
    <components.Control style={{ width: "auto" }} {...props}>
      {!hideFilterIcon && <div
				style={{
					paddingLeft: "10px",
					color: user?.themePreference === "dark" ? "#ede5e5" : "rgba(0, 0, 0, 0.54)",
				}}
			>
        <FontAwesomeIcon icon="fa-solid fa-filter" />
      </div>}
      {children}
    </components.Control>
  )
};


export default SelectDropDown;