import {ReactComponent as NetopsDashboardIcon} from "../netopsStyles/assets/sidebar/dashboard.svg";
import {ReactComponent as NetopsChangeLogsIcon} from "../netopsStyles/assets/sidebar/change_logs.svg"
import {ReactComponent as NetopsDeviceMappingcon} from "../netopsStyles/assets/sidebar/device_mapping.svg"
import {ReactComponent as NetopsDeviceIcon} from "../netopsStyles/assets/sidebar/devices.svg"
import {ReactComponent as NetopsFlappingLogsIcon} from "../netopsStyles/assets/sidebar/flapping_logs.svg"
import {ReactComponent as NetopsGatewayMappingIcon} from "../netopsStyles/assets/sidebar/gateway_mapping.svg"
import {ReactComponent as NetopsInventoryAuditIcon} from "../netopsStyles/assets/sidebar/inv_audit_report.svg"
import {ReactComponent as NetopsGatewayIcon} from "../netopsStyles/assets/sidebar/gateway.svg"
import {ReactComponent as NetopsLinkMonitoringIcon} from "../netopsStyles/assets/sidebar/link_monitoring.svg";
import {ReactComponent as NetopsCircuitMonitoringIcon} from "../netopsStyles/assets/sidebar/circuit_monitoring.svg";
import {ReactComponent as NetopsServiceIcon} from "../netopsStyles/assets/sidebar/service.svg"
import {ReactComponent as NetopsSiteIcon} from "../netopsStyles/assets/sidebar/site.svg";
import {ReactComponent as NetopsTenantIcon} from "../netopsStyles/assets/sidebar/tenant.svg";
import {ReactComponent as NetopsUserManagementIcon} from "../netopsStyles/assets/sidebar/user_mgt.svg";
import {ReactComponent as NetopsWebmonitorIcon} from "../netopsStyles/assets/sidebar/web_monitor.svg";
import {ReactComponent as NetopsTicketIcon} from "../netopsStyles/assets/sidebar/ticket.svg";
import {ReactComponent as NetopsGatewayPhoneNumberIcon} from "../netopsStyles/assets/sidebar/gateway_phone_number.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const formTypes = (user) => {
return [
	{
		category: "dashboard",
		title: "Dashboard",
		resources: [],
		netopsIcon: <NetopsDashboardIcon className="icon" />,
		activeLink: ["/", "/nf-status", "/nf-status/dashboard"],
		formParameters: [],
		orderIndex: 1,
		isEnabled: true
	},
	{
		category: "tenants",
		title: "Tenants",
		resources: ['tenants'],
		netopsIcon: <NetopsTenantIcon style={{width:"19px", height:"19px"}} className="icon" />,
		activeLink: ["/nf-status/tenants", "/nf-status/tenants/changelog", "/nf-status/tenants/contact"],
		formParameters: [
			{
				page: "1",
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "1",
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Select Enterprise",
				identifier: "enterprice",
				parameterType: "ListSingle",
				defaultValue: "Velo cloud",
				validationRules: ["required"],
				optionList: [
					{
						name: "Velo cloud",
						value: "velo-cloud",
					},
					{
						name: "Custom",
						value: "custom",
					},
				],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Token Type",
				identifier: "tokenType",
				parameterType: "ListSingle",
				defaultValue: "Global",
				optionList: [
					{
						name: "Global",
						value: "global",
					},
					{
						name: "Custom",
						value: "custom",
					},
				],
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Key",
				identifier: "key",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 6,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Token",
				identifier: "token",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 6,
				widthxs: 12,
			},
		],
		isEnabled: user?.tenant?.name === 'Sangoma' && user?.userRole !== 'user',
		orderIndex: 2,
	},
	{
		category: "site",
		title: "Site",
		resources: ['sites'],
		netopsIcon: <NetopsSiteIcon className="icon" />,
		activeLink: ["/nf-status/site"],
		formParameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Enter Name",
				maxChar: 90,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				isDisable: false
			},
			{
				title: "Display Name",
				identifier: "displayname",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [""],
				placeholder: "Enter Name",
				maxChar: 90,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Status",
				identifier: "status",
				parameterType: "Status_ListSingle",
				optionList: [
					{
						label: "Active",
						value: "active",
					},
					{
						label: "Staging",
						value: "staging",
					},
					{
						label: "Retired",
						value: "retired",
					},
				],
				defaultValue: "",
				validationRules: [],
				placeholder: "Select Status",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Timezone",
				identifier: "time_zone",
				parameterType: "ListSingle",
				defaultValue: "",
				validationRules: [],
				placeholder: "Select Timezone",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Description",
				maxChar: 180,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			// {
			// 	title: "Service",
			// 	identifier: "Service",
			// 	parameterType: "Label",
			// 	defaultValue: '',
			// 	validationRules: [],
			// 	placeholder: "Enter Description",
			// 	widthMd: 12,
			// 	widthsm: 12,
			// 	widthxs: 12,
			// },
			// {
			// 	title: "SDWAN",
			// 	identifier: "velocloud",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "WIFI",
			// 	identifier: "meraki",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 1.8,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Circuits",
			// 	identifier: "wug",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Cradlepoint",
			// 	identifier: "cradlepoint",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2.5,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Fortinet",
			// 	identifier: "fortinet",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Tenant",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Remote Site",
				identifier: "remote_site",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Cloud Site",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Physical Address",
				identifier: "physical_address",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Physical Address",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 180,
			},
			{
				title: "Shipping Address",
				identifier: "shipping_address",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Shipping Address",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 180,
			},
			{
				title: "Latitude",
				identifier: "latitude",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Latitude",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 11,
			},
			{
				title: "Longitude",
				identifier: "longitude",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Longitude",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 12,
			},
		],
		isEnabled: true,
		orderIndex: 3,
	},
	{
		category: "binding",
		title: "Device mapping",
		resources: ['binding'],
		netopsIcon: <NetopsDeviceMappingcon className="icon" />,
		activeLink: ["/nf-status/binding"],
		formParameters: [],
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isMSPEnabled,
		orderIndex: 8,
	},
	{
		category: "gateway-binding",
		title: "Gateway mapping",
		resources: ['gateways'],
		netopsIcon: <NetopsGatewayMappingIcon style={{width:"30px"}} className="icon" />,
		activeLink: ["/nf-status/gateway-binding"],
		formParameters: [],
		isEnabled: user?.tenant?.name === 'Sangoma',
		orderIndex: 9,

	},
	{
		category: "inventory",
		title: "Devices",
		resources: ['devices'],
		netopsIcon: <NetopsDeviceIcon className="icon" />,
		activeLink: ["/nf-status/inventory"],
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isMSPEnabled,
		formParameters: [
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Tenant",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Site Name",
				identifier: "site_name",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Site Name",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Device Role",
				identifier: "device_role",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Device Role",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 90,
			},
			{
				title: "Device Type",
				identifier: "device_type",
				parameterType: "lineDropDowns",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Device type",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Serial Number",
				titleSide: "IP Address",
				identifier: "serial",
				identifierSide: "ip_address",
				parameterType: "lineInputs",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 20,
			},
			{
				title: "State",
				identifier: "state",
				parameterType: "Status_ListSingle",
				defaultValue: "",
				validationRules: ["required"],
				optionList: [
					{
						label: "Active",
						value: "active",
					},
					{
						label: "Staging",
						value: "staging",
					},
					{
						label: "Offline",
						value: "offline",
					},
					{
						label: "Planned",
						value: "planned",
					},
					{
						label: "Inventory",
						value: "inventory",
					},
					{
						label: "Decommissioning",
						value: "decommissioning",
					},
				],
				placeholder: "Select status",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
		orderIndex: 4,
	},
	{
		category: "gateway",
		title: "Gateway Managment",
		resources: ['gateways'],
		netopsIcon: <NetopsGatewayIcon style={{width:"30px"}} className="icon" />,
		activeLink: ["/nf-status/gateway"],
		formParameters: [],
		orderIndex: 5,
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isGatewayEnabled,
	},
	{
		category: "phone-numbers",
		title: "Phone Numbers",
		resources: ['gateways'],
		netopsIcon: <NetopsGatewayPhoneNumberIcon style={{width:"30px"}} className="icon" />,
		activeLink: ["/nf-status/phone-numbers"],
		formParameters: [],
		orderIndex: 5,
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isGatewayEnabled,
	},
	{
		category: "sync-inventory",
		title: "Inv Audit Report",
		resources: ['sync-inventory'],
		netopsIcon: <NetopsInventoryAuditIcon className="icon" />,
		activeLink: ["/nf-status/sync-inventory"],
		formParameters: [],
		orderIndex: 10,
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isMSPEnabled,
	},
	{
		category: "service",
		hide: true,
		title: "Service",
		resources: ['services'],
		netopsIcon: <NetopsServiceIcon className="icon" />,
		activeLink: ["/nf-status/service"],
		formParameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
		orderIndex: 11,
		isEnabled: user?.tenant?.name === 'Sangoma',
	},
	{
		category: "link-monitoring",
		title: "Link Monitoring",
		resources: ['link-monitoring'],
		netopsIcon: <NetopsLinkMonitoringIcon className="icon" />,
		activeLink: ["/nf-status/link-monitoring"],
		formParameters: [],
		orderIndex: 7,
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isMSPEnabled,
	},
	{
		category: "circuit-link-monitoring",
		title: "Circuit Monitoring",
		resources: ['circuit-monitoring'],
		netopsIcon: <NetopsCircuitMonitoringIcon className="icon" />,
		activeLink: ["/nf-status/circuit-link-monitoring"],
		formParameters: [],
		orderIndex: 8,
		isEnabled: user?.tenant?.name === 'Sangoma' || (user?.isMSPEnabled && user?.email?.toLowerCase()?.includes("sangoma.com")),
	},
	{
		category: "change-logs",
		title: "Change Logs",
		resources: ['changelogs'],
		netopsIcon: <NetopsChangeLogsIcon className="icon" />,
		activeLink: ["/nf-status/change-logs"],
		formParameters: [],
		orderIndex: 13,
		isEnabled: true
	},
	{
		category: "user-management",
		title: "User Management",
		netopsIcon: <NetopsUserManagementIcon className="icon" />,
		resources: ['role-permissions', 'users', 'roles', 'user-management-logs'],
		activeLink: ["/nf-status/user-management"],
		formParameters: [],
		orderIndex: 12,
		isEnabled: user?.tenant?.name === 'Sangoma',
	},
	{
		category: "flapping-logs",
		title: "Flapping Logs",
		resources: ['eventlogs'],
		netopsIcon: <NetopsFlappingLogsIcon className="icon" />,
		activeLink: ["/nf-status/flapping-logs"],
		formParameters: [],
		orderIndex: 14,
		isEnabled: user?.tenant?.name === 'Sangoma' || user?.isMSPEnabled,
	},
	{
		category: "web-monitor",
		title: "Web Monitor",
		resources: ['web-monitor'],
		netopsIcon: <NetopsWebmonitorIcon className="icon" />,
		activeLink: ["/nf-status/web-monitor"],
		formParameters: [],
		orderIndex: 15,
		isEnabled: true,
	},
	{
		category: "tickets",
		title: "Tickets",
		resources: ["tickets"],
		netopsIcon: <NetopsTicketIcon className="icon" />,
		activeLink: ["/nf-status/tickets"],
		formParameters: [],
		orderIndex: 16,
		isEnabled: true,
	},
	{
		category: "phones",
		title: "Endpoints",
		resources: [],
		netopsIcon: <FontAwesomeIcon icon="fa-solid fa-phone" />,
		activeLink: ["/nf-status/phones"],
		formParameters: ['endpoints'],
		orderIndex: 6,
		isEnabled:user?.isPhoneEnabled,
	}
]
}
