import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "assets/less/main.less";
import { AuthProvider } from "context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes";
// import ReactGA from 'react-ga4';
import {  ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import { useMemo } from "react";

function App() {
	// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
	const user = useSelector((state) => state.user);

    const theme = useMemo(() => {
        const themeOptions = {
            palette: {
                mode: user?.themePreference === "dark" ? "dark" : "light",
				common:{
					black: '#222831',
					white:'#fff'
				},
				background:{
					paper:user?.themePreference === "dark" ?'#222831' : "#fff",
					default:user?.themePreference === "dark" ?'#222831' : "#fff",
				}
            },

        };
        return createTheme(themeOptions);
    }, [user?.themePreference]); 
	
	return (
		<ThemeProvider theme={ theme}>
		<CssBaseline />

			<ToastContainer
				position="top-center"
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<AuthProvider>
				<Routes key={user?.themePreference} />
			</AuthProvider>
		</ThemeProvider>
	);
}

export default App;
library.add(fas);
