import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getStatusColor, getStatusTextColor } from './ticketsConstants';
import { setTicketDetails } from 'store/tickets';

export default function TicketsComponent({ tickets, uniqueId, previousScreen, previousScreenURL, tenantName, tenantId }) {
    const navigate = useNavigate();
    const user = useSelector(state => state?.user);
    const [ticketList, setTicketList] = useState([]);
    const dispatch = useDispatch()

    const handleClick = useCallback((e, ticket) => {
        e?.stopPropagation();
        dispatch(setTicketDetails({
            ticketId: ticket?.ticket_id,
            caseNumber: ticket?.case_number,
            device_id: ticket?.device_id,
            service: ticket?.service,
            tenantName: tenantName,
            tenantId: tenantId,
            previousScreen: previousScreen,
            previousScreenURL: previousScreenURL,
            ticketingSystem: ticket?.ticketType === "salesforceTickets" ? "salesforce" : ticket?.ticketType === "serviceNowTickets" ? "servicenow" : null
          }))
        navigate(`/nf-status/ticket`)
    }, [previousScreen, previousScreenURL, user?.tenant?.name, tenantName, tenantId]);

    useEffect(() => {
        const ticketOptionsList = [];

        if (tickets && Object.keys(tickets).length > 0) {
            Object.entries(tickets).forEach(([ticketType, ticketList]) => {
                if (ticketType === "salesforceTickets" || ticketType === "serviceNowTickets") {
                    const mappedTickets = ticketList.map(item => ({
                        created_at: item?.created_at,
                        updated_at: item?.updated_at,
                        id: item?.id,
                        tenant_id: item?.tenant_id,
                        ticket_id: item?.ticket_id,
                        site_id: item?.site_id,
                        subject: item?.subject,
                        service: item?.service,
                        status: item?.status,
                        priority: item?.priority,
                        is_ticket_viewed: item?.is_ticket_viewed,
                        device_id: item?.device_reference_id,
                        case_number: ticketType === "salesforceTickets" ? item?.case_number : item?.number,
                        ticketType: ticketType
                    }));
                    ticketOptionsList.push(...mappedTickets);
                }
            });
        }
        setTicketList(ticketOptionsList);
    }, [tickets]);

    const filteredTickets = useMemo(() => {
        if (ticketList?.length === 1 && ticketList[0].status?.toLowerCase() === 'closed') {
            return ticketList;
        }
        return ticketList?.filter(ticket => ticket.status?.toLowerCase() !== 'closed') || [];
    }, [ticketList]);


    return (
        <>
            <Box
                key={uniqueId}
                id={uniqueId}
                className='link__monitoring__ticket__wrapper'
                sx={{ display: "flex" }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {filteredTickets && filteredTickets?.length > 0 ?
                        (filteredTickets?.map((ticket, idx) => {

                            return (
                                <Box key={idx} sx={{ padding: "0px 4px", display: "flex", alignItems: "center", cursor: "pointer" }} >

                                    <Chip
                                        className='ticket__chip'
                                        label={ticket?.case_number}
                                        sx={{
                                            bgcolor: getStatusColor(ticket?.status?.toLowerCase()),
                                            color: getStatusTextColor(ticket?.status?.toLowerCase()),
                                            display: "flex", alignItems: "center", justifyContent: "center",
                                            '&:hover': {
                                                bgcolor: getStatusColor(ticket?.status?.toLowerCase()),
                                                color: getStatusTextColor(ticket?.status?.toLowerCase()),

                                            }
                                        }}
                                        onClick={(e) => handleClick(e, ticket)}
                                    />
                                    {
                                        <Box sx={{ width: "20px", height: "20px" }}>
                                            {(ticket?.is_ticket_viewed === false) &&
                                                <FontAwesomeIcon style={{ paddingLeft: "8px", marginBottom: "-4px", color: "#FFAA71", fontSize: "18px" }} icon="fa-solid fa-bell" />
                                            }
                                        </Box>
                                    }

                                </Box>

                            )

                        }

                        ))
                        :
                        <div>--</div>
                    }
                </Box>
            </Box>
        </>
    );
}
