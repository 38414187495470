import { Box, Button, Card, CircularProgress, Switch, Typography, styled } from "@mui/material"
import { BlockUI } from "ns-react-block-ui"
import { useSelector } from "react-redux"
import Select from "react-select"

export const MuiThemedBox = ({ children, className, styles }) => {
  return (
    <Box className={className} sx={{
      backgroundColor: (theme) => theme.palette.mode === "dark"
        ? "#222831 !important"
        : "",
        ...styles
    }}>
      {children}
    </Box>
  )
}


export const MuiThemedCard = ({ children, className }) => {
  return (
    <Card className={className} sx={{
      backgroundColor: (theme) => theme.palette.mode === "dark"
        ? "#31363F !important"
        : "",
    }}>
      {children}
    </Card>
  )
}

export const MuiTypography = ({ children, className }) => {
  return (
    <Typography className={className} sx={{
      color: (theme) => theme.palette.mode === "dark"
        ? "#fff !important"
        : "",
    }}>
      {children}
    </Typography>
  )
}

export const MuiThemedButton = ({ children,showLoader, className, variant, color, disable,blockUIStyle,  ...rest }) => {
  const user = useSelector(state => state?.user)
  return (
    <BlockUI blocking={showLoader} loader={<MuiThemedCircularProgress />} className='flex' style={blockUIStyle}>
    <Button
      className={className}
      disabled = {disable}
      variant={variant}
      sx={{
        '&:hover': {
          backgroundColor: (theme) => {
            if (variant === 'contained') {
              if (color === 'success') {
                return '#2e7d32 !important'
              } else if (color === 'error') {
                return '#d32f2f !important'
              } else {
                return theme.palette.mode === 'dark' ? 'rgb(66, 165, 245)  !important ' : '#244C91 !important';
              }

            } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
              return 'rgba(144, 202, 249, 0.08)  !important';
            }

          },

          color: (theme) => {
            if (variant === 'contained') {
              if (color === 'success' || color ==='error') {
                return '#fff !important'

              } else {
                return theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.87)' : '';
              }
            } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
              return 'rgb(144, 202, 249) !important'
            }
          },
        },
        backgroundColor: (theme) => {
          if (disable &&  user?.themePreference === 'light') return '#f0f0f0 !important';
          if (variant === 'contained') {
            if (color === 'success') {
              return '#2e7d32 !important'
            } else if (color === 'error') {
              return '#d32f2f !important'
            } else {
              return theme.palette.mode === 'dark' ? 'rgb(144, 202, 249)  !important ' : '#244C91';
            }

          } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return 'transparent  !important';
          }

        },
        color: (theme) => {
          if (disable && user?.themePreference === 'light') return '#a0a0a0 !important';
          if (variant === 'contained') {
            if (color === 'success' || color ==='error') {
              return '#fff !important'

            } else {
              return theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.87)' : '';
            }
          } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return 'rgb(144, 202, 249) !important'
          }

        },
        border:(theme) =>{
          if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return '1px solid rgb(144, 202, 249) !important'
          }

        }
      }}
      {...rest}
    >
      {children}
    </Button>
    </BlockUI>

  )
}

export const MuiThemedSwitch = styled(Switch)(({ theme }) => ({
  width: 58,
  height: 30,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#232D3F' : '#fff',
    width: 28,
    height: 28,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      border: '1px solid #fff',
      borderRadius: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#232D3F',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


export const ThemedReactSelect = ({ isRequired, ...props }) => {

  const user = useSelector(state => state?.user);
  const selectStyle = user?.themePreference === 'dark' ? {...darkThemeReactSelectStyles, ...props?.customStyles} : props?.customStyles;
  
  return (
    <Select
      classNamePrefix={`${props.classNamePrefix || ''} ${isRequired ? ' react__select__required' : ''}`}
      styles={selectStyle}
      isLoading={props?.isLoading}
      {...props}
    />
  )
}

export const darkThemeReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #caced6',
    color: state.isSelected ? '#262a30' : '#fff',
    backgroundColor: state.isSelected ? '#999eaa' : '',
    boxShadow:'0 0 0 1px hsl(0deg 2.98% 58.56% / 10%), 0 4px 11px hsl(0deg 6.08% 86.38% / 10%)',
    '&:active': {
      backgroundColor: '#dee1e7db',
      color: ' #393030'
    },
    '&:hover': {
      backgroundColor: '#dee1e7db',
      color: ' #393030'
    }
  }),
  control: (provided) => ({
    ...provided,
    boxShadow:'0 0 0 1px #54606d',
    backgroundColor: 'transparent !important',
    borderColor:'#383d43 !important',

  }),
  input:(provided) => ({
    ...provided,
    color: '#fff !important'
  }),
  singleValue: (provided) =>({
    ...provided,
    color: '#fff'
  }),
  menu: (provided) =>({
    ...provided,
    backgroundColor: '#31363F !important',
  })

}

export const ReactDataTableStyles = {
  text: {
    primary: '#fff !important',

  },
  background: {
    default: '#31363F',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}

export const MuiThemedCircularProgress = () => {
  const user = useSelector(state => state?.user);
  const themePreference = user?.themePreference;
  return(
    
    <CircularProgress  style={{
      color: themePreference==="dark" ?"#fff":"#244C91"
    }}/>
  )
}