import { Box, Typography } from '@mui/material'
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import SearchInputField from 'components/common/SearchInputField';
import TableComponent from 'components/common/TableComponent/TableComponent';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import moment from 'moment-timezone';
import React, { useState } from 'react'
import { customSearch, customSort, trimSpacesFromStartAndEnd } from 'utils/Utilities';
  

const EmailList = ({ emails, totalEmailCount, handleEmailBodyPopup }) => {

    const [filteredData, setFilteredData] = useState(emails);
    const [searchInputValue, setSearchInputValue] = useState();

    const columns = [
        {
            name: "Subject",
            id: "Subject",
            title: "Subject",
            dataType: "String",
            isEnabled: true,
            minWidth: 120,
            // wrap: true,
            sortable: true,
            cell: (row) => {
                return (
                    <Typography component={"div"} className="ticket__sidebar__card--value--subject" onClick={() => handleEmailBodyPopup(true, row)}>
                        <TooltipLabel text={row?.Subject} />
                    </Typography>

                )
            },
        },
        {
            title: "From",
            name: "From",
            id: "FromAddress",
            dataType: "String",
            isEnabled: true,
            minWidth: 250,
            sortable: true,
            // grow: "0 !important",
            cell: (row) => <CopyTextCell showWithTooltip={true} text={row?.FromAddress} />,
        },
        {
            title: "To",
            name: "To",
            id: "ToAddress",
            dataType: "String",
            isEnabled: true,
            minWidth: 250,
            // grow: "0 !important",
            sortable: true,
            cell: (row) => <CopyTextCell showWithTooltip={true} text={row?.ToAddress} />,
        },
        {
            title: "Date & Time",
            name: "Date & Time",
            id: "MessageDate",
            dataType: "String",
            isEnabled: true,
            minWidth: 200,
            // grow: "0 !important",
            sortable: true,
            cell: (row) => {
                const date = moment(new Date(row?.MessageDate)).format('LLL');
                return (
                    <TooltipLabel text={date ? date : "-"} />
                )
            }
        }
    ]

    const handleSearch = async (e) => {
        const searchString = trimSpacesFromStartAndEnd(e?.target?.value)
        setSearchInputValue(searchString);
        let searchResult = await customSearch(searchString, emails);
        setFilteredData(searchResult);
    }

    const customSortFunction = async (column, direction, data) => {
        setFilteredData(customSort(column, direction, data));
    }

    return (
        <Box sx={{ padding: "24px 0" }}>
            <Box className="filter_wrap">
                <SearchInputField handleClick={handleSearch} value={searchInputValue} />
            </Box>
            <TableComponent
                className="rdt_table_wrap table_with_header"
                columns={columns}
                rows={filteredData}
                pagination={true}
                customSortFunction={customSortFunction}
                totalRows={totalEmailCount}
            />
        </Box>
    )
}

export default EmailList
