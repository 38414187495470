import React, {useEffect, useState,useRef} from 'react'
import Tooltip,  { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';


export default function TooltipLabel({text, width, fontStyle,...props}) {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const description = ref.current;
     
        description && description.scrollWidth > description.clientWidth &&
          setIsOverflowed(true);
      }, [props?.removeDataTag, text]);

      const Div = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: props?.fontSize || '13px',
        width:width,
        ...fontStyle
}

  return (
    <React.Fragment>
    <StyledTooltip key={text} title={text} disableHoverListener={!isOverflowed} arrow width={width ? width : '100%'} {...props}>
        <div  ref = {ref} style = {Div} data-tag={props?.removeDataTag  ? null :"allowRowEvents"} {...props}>
          {text}
        </div>
    </StyledTooltip>
  </React.Fragment>
  )
}


export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
  {...props}
  arrow
  classes={{ popper: className }}
/>
))(({ theme, width }) => ({
[`& .${tooltipClasses.arrow}`]: {
  color: theme.palette.mode === "dark" ? "#4C5863" : "#001221", 
},
[`& .${tooltipClasses.tooltip}`]: {
  fontSize: "12px",
  fontFamily: "Open Sans, sans-serif",
  fontWeight: 400,
  color: theme.palette.mode === "dark" ? "#E5E6E8" : "#fff", 
  backgroundColor: theme.palette.mode === "dark" ? "#4C5863" : "#001221", 
  marginTop: "0px !important",
  width: width? width: "auto", 
},
}));