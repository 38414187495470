import { Box, Grid, Typography } from '@mui/material'
import { MuiThemedCircularProgress, MuiTypography } from 'components/common/MuiThemedComponents'
import React from 'react'
import './TableHeader.css'
import { StyledTooltip } from '../TooltipLabel/TooltipLabel';
import help from '../../../assets/icons/help.png';
import helpDarkTheme from '../../../assets/icons/help_dark_theme.png'
import { useSelector } from 'react-redux';

const TableHeader = ({ tableHeaderCardDetails, isLoading, handleCardClick, md, lg }) => {

    const user = useSelector((state) => state.user);

    function removeTrailingZeros(num) {
        const val = num?.toString().replace(/\.00$/, '');    
        return Number(val)?.toFixed(2);
      }

    return (
        <Grid container className='table__header_upper_card table__header__card__wrap'>
            {tableHeaderCardDetails && tableHeaderCardDetails?.length > 0 && tableHeaderCardDetails?.filter(i => i.isEnabled)?.map((item, index) => {
                return (
                    <Grid className={item?.className} sx={{ padding: '20px 0px 20px 20px', flexGrow: 1, }} key={item?.id} item xs={12} md={md || 2.4} lg={lg || 2.4}>
                        <div className="table__header__box">

                            {isLoading ?
                                <MuiThemedCircularProgress  />
                                :

                                <div className="table__header__card" style={{ cursor: item?.count === 0 || item?.disableClick ? "default" : "" }} onClick={(e) => handleCardClick && item?.count !== 0 && !item?.disableClick && handleCardClick(e, item)} >                               
                                    <div className="table__header__card__header__wrap">
                                        {item?.tooltipText ?
                                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography component="div" className="table__header__card__header" sx={{paddingRight: '4px'}}>

                                                    {item?.text}
                                                </Typography>
                                                <StyledTooltip placement="top" title={item?.tooltipText} width = "230px">
                                                    <img src={user?.themePreference === 'dark' ? helpDarkTheme : help} alt='help' style={{ width: '20px', height: '20px' }} />
                                                </StyledTooltip>                                            
                                            </Box>
                                            :

                                            <MuiTypography component="div" className="table__header__card__header">

                                                {item?.text}
                                            </MuiTypography>

                                        }

                                        <MuiTypography component="div" className="table__header__card__count">
                                            {item?.count || 0}
                                        </MuiTypography>
                                    </div>

                                    <div className="table__header__card__details">
                                        <MuiTypography component="div" className="table__header__percentage">
                                            {removeTrailingZeros(item?.percentage) || 0}%

                                        </MuiTypography>
                                    </div>
                                </div>
                            }
                            {index + 1 != tableHeaderCardDetails?.length && <div style={{ height: "50px" }} className="table__header__card__border"></div>}

                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TableHeader
