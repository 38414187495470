import { ReactComponent as SiteIcon } from "assets/icons/site.svg";
import { ReactComponent as ServiceIcon } from "assets/icons/cloudService.svg";
import { ReactComponent as InventoryIcon } from "assets/icons/inventory.svg";
import { ReactComponent as TenantIcon } from "assets/icons/tenant.svg";
import { ReactComponent as DashbaordIcon } from "assets/icons/dashboard.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as BindingIcon } from "assets/icons/binding.svg";
import { ReactComponent as MonitoringIcon } from "assets/icons/linkMonitor.svg";

export const formTypes = [
	{
		category: "dashboard",
		title: "Dashboard",
		resources: [],
		icon: <DashbaordIcon className="sideIcon " />,
		darkModeIcon:<DashbaordIcon className="sideIcon darkMode" />,
		activeLink: ["/", "/nf-status", "/nf-status/dashboard"],
		formParameters: [],
		orderIndex: 1,
	},
	{
		category: "tenants",
		title: "Tenants",
		resources: ['tenants'],
		icon: <TenantIcon className="sideIcon" />,
		darkModeIcon:<TenantIcon className="sideIcon darkMode" />,
		activeLink: ["/nf-status/tenants", "/nf-status/tenants/changelog", "/nf-status/tenants/contact"],
		formParameters: [
			{
				page: "1",
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "1",
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Select Enterprise",
				identifier: "enterprice",
				parameterType: "ListSingle",
				defaultValue: "Velo cloud",
				validationRules: ["required"],
				optionList: [
					{
						name: "Velo cloud",
						value: "velo-cloud",
					},
					{
						name: "Custom",
						value: "custom",
					},
				],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Token Type",
				identifier: "tokenType",
				parameterType: "ListSingle",
				defaultValue: "Global",
				optionList: [
					{
						name: "Global",
						value: "global",
					},
					{
						name: "Custom",
						value: "custom",
					},
				],
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Key",
				identifier: "key",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 6,
				widthxs: 12,
			},
			{
				page: "2",
				title: "Token",
				identifier: "token",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 6,
				widthxs: 12,
			},
		],
		orderIndex: 2,
	},
	{
		category: "site",
		title: "Site",
		resources: ['sites'],
		icon: <SiteIcon className="site_icon" />,
		darkModeIcon:<SiteIcon className="sideIcon site_icon darkMode" />,
		activeLink: ["/nf-status/site"],
		formParameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Enter Name",
				maxChar: 90,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				isDisable: false
			},
			{
				title: "Display Name",
				identifier: "displayname",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [""],
				placeholder: "Enter Name",
				maxChar: 90,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Status",
				identifier: "status",
				parameterType: "Status_ListSingle",
				optionList: [
					{
						label: "Active",
						value: "active",
					},
					{
						label: "Staging",
						value: "staging",
					},
					{
						label: "Retired",
						value: "retired",
					},
				],
				defaultValue: "",
				validationRules: [],
				placeholder: "Select Status",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Timezone",
				identifier: "time_zone",
				parameterType: "ListSingle",
				defaultValue: "",
				validationRules: [],
				placeholder: "Select Timezone",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Description",
				maxChar: 180,
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			// {
			// 	title: "Service",
			// 	identifier: "Service",
			// 	parameterType: "Label",
			// 	defaultValue: '',
			// 	validationRules: [],
			// 	placeholder: "Enter Description",
			// 	widthMd: 12,
			// 	widthsm: 12,
			// 	widthxs: 12,
			// },
			// {
			// 	title: "SDWAN",
			// 	identifier: "velocloud",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "WIFI",
			// 	identifier: "meraki",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 1.8,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Circuits",
			// 	identifier: "wug",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Cradlepoint",
			// 	identifier: "cradlepoint",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2.5,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			// {
			// 	title: "Fortinet",
			// 	identifier: "fortinet",
			// 	parameterType: "Checkbox",
			// 	defaultValue: true,
			// 	validationRules: ["required"],
			// 	widthMd: 2,
			// 	widthsm: 4,
			// 	widthxs: 6,
			// },
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Tenant",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Remote Site",
				identifier: "remote_site",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Cloud Site",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Physical Address",
				identifier: "physical_address",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Physical Address",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 180,
			},
			{
				title: "Shipping Address",
				identifier: "shipping_address",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Shipping Address",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 180,
			},
			{
				title: "Latitude",
				identifier: "latitude",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Latitude",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 10,
			},
			{
				title: "Longitude",
				identifier: "longitude",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				placeholder: "Enter Longitude",
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 10,
			},
		],
		orderIndex: 3,
	},
	{
		category: "binding",
		title: "Device mapping",
		resources: ['binding'],
		icon: <BindingIcon className="sideIcon" />,
		darkModeIcon:<BindingIcon className="sideIcon darkMode" />,
		activeLink: ["/nf-status/binding"],
		formParameters: [],
		orderIndex: 6,
	},
	{
		category: "inventory",
		title: "Devices",
		resources: ['devices'],
		icon: <InventoryIcon className="sideIcon" />,
		darkModeIcon:<InventoryIcon className="sideIcon darkMode" />,
		activeLink: ["/nf-status/inventory"],
		formParameters: [
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Tenant",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Site Name",
				identifier: "site_name",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Site Name",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Device Role",
				identifier: "device_role",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Device Role",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 90,
			},
			{
				title: "Device Type",
				identifier: "device_type",
				parameterType: "lineDropDowns",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Device type",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Serial Number",
				titleSide: "IP Address",
				identifier: "serial",
				identifierSide: "ip_address",
				parameterType: "lineInputs",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
				maxChar: 20,
			},
			{
				title: "State",
				identifier: "state",
				parameterType: "Status_ListSingle",
				defaultValue: "",
				validationRules: ["required"],
				optionList: [
					{
						label: "Active",
						value: "active",
					},
					{
						label: "Staging",
						value: "staging",
					},
					{
						label: "Offline",
						value: "offline",
					},
					{
						label: "Planned",
						value: "planned",
					},
					{
						label: "Inventory",
						value: "inventory",
					},
					{
						label: "Decommissioning",
						value: "decommissioning",
					},
				],
				placeholder: "Select status",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
		orderIndex: 4,
	},
	{
		category: "sync-inventory",
		title: "Inv Audit Report",
		resources: ['sync-inventory'],
		icon: (
			<FontAwesomeIcon
				icon="fa-solid fa-rotate"
				style={{ height: "22px", color: "#4c5863" }}
			/>
		),
		darkModeIcon:	<FontAwesomeIcon
		className="sideIcon darkMode"
		icon="fa-solid fa-rotate"
		style={{ height: "22px", color: "#fff" }}
	/>,
		activeLink: ["/nf-status/sync-inventory"],
		formParameters: [],
		orderIndex: 7,
	},
	{
		category: "service",
		hide: true,
		title: "Service",
		resources: ['services'],
		icon: <ServiceIcon className="sideIcon" />,
		darkModeIcon: <ServiceIcon className="sideIcon darkMode" />,
		activeLink: ["/nf-status/service"],
		formParameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
		orderIndex: 8,
	},
	{
		category: "link-monitoring",
		title: "Link Monitoring",
		resources: ['link-monitoring'],
		icon: <MonitoringIcon className="sideIcon" />,
		darkModeIcon: <MonitoringIcon className="sideIcon darkMode" />,
		activeLink: ["/nf-status/link-monitoring"],
		formParameters: [],
		orderIndex: 5,
	},
	{
		category: "change-logs",
		title: "Change Logs",
		resources: ['changelogs'],
		icon: <FontAwesomeIcon className="sideIcon user_management_icon" icon="fa-solid fa-book" />,
		darkModeIcon: <FontAwesomeIcon className="sideIcon darkMode user_management_icon" icon="fa-solid fa-book" />,
		activeLink: ["/nf-status/change-logs"],
		formParameters: [],
		orderIndex: 10,
	},
	{
		category: "user-management",
		title: "User Management",
		resources: ['role-permissions', 'users', 'roles', 'user-management-logs'],
		icon: <FontAwesomeIcon className="sideIcon user_management_icon" icon="fa-solid fa-users-gear" />,
		darkModeIcon: <FontAwesomeIcon className="sideIcon darkMode user_management_icon"  icon="fa-solid fa-users-gear" />,
		activeLink: ["/nf-status/user-management"],
		formParameters: [],
		orderIndex: 9,
	},
	{
		category: "flapping-logs",
		title: "Flapping Logs",
		resources: ['eventlogs'],
		icon: <FontAwesomeIcon className="sideIcon user_management_icon" icon="fa-solid fa-clock" />,
		darkModeIcon: <FontAwesomeIcon className="sideIcon darkMode user_management_icon"  icon="fa-solid fa-clock" />,
		activeLink: ["/nf-status/flapping-logs"],
		formParameters: [],
		orderIndex: 11,
	},
	{
		category: "web-monitor",
		title: "Web Monitor",
		resources: ['web-monitor'],
		icon: <FontAwesomeIcon className="sideIcon user_management_icon" icon="fas fa-desktop" />,
		darkModeIcon: <FontAwesomeIcon className="sideIcon darkMode user_management_icon"  icon="fas fa-desktop" />,
		activeLink: ["/nf-status/web-monitor"],
		formParameters: [],
		orderIndex: 12,
	},
	{
		category: "tickets",
		title: "Tickets",
		resources: [],
		icon: <FontAwesomeIcon className="sideIcon user_management_icon" icon="fa-solid fa-ticket" />,
		darkModeIcon: <FontAwesomeIcon className="sideIcon darkMode user_management_icon"  icon="fa-solid fa-ticket" />,
		activeLink: ["/nf-status/tickets"],
		formParameters: [],
		orderIndex: 12,
	}
];
