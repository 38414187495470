import moment from "moment-timezone";
import { toast } from "react-toastify";

export const isStatusColumn = (row, col) => {
	if (Object?.keys(row)?.includes("status") && col.name === "status") {
		return true;
	} else if (
		Object?.keys(row)?.includes("inventory_status") &&
		col.name === "inventory_status"
	) {
		return true;
	}
	return false;
};

export const filterColumns = (columns) => {
	let cols = [];
	for (let i = 0; i < columns?.length; i++) {
		const col = columns[i];
		cols.push({
			id: col?.name,
			name: col?.title?.toUpperCase(),
			title: col?.title,
			selector: (row) => {
				if (isStatusColumn(row, col)) {
					return (
						<span
							className={
								row[col?.name]?.toLowerCase() === "decommissioning"
									? "status status_down"
									: row?.countActiveStatus && row?.countActiveStatus === row?.totalDevices
										? "status status_active"
										: row?.countActiveStatus === 0
											? "status status_down"
											: row?.countActiveStatus > 0 && row?.countActiveStatus < row?.totalDevices
												? "status status_inactive"
												: row[col?.name]?.toLowerCase() === "up"
													? "status status_active"
													: row[col?.name]?.toLowerCase() === "down"
														? "status status_down"
														: "status status_inactive"
							}
						>
							{row[col.name]}
						</span>
					);
				} else if (
					Object?.keys(row)?.includes("remark") &&
					col.name === "remark"
				) {
					return (
						<div className={row?.remark === "Mismatch Found" ? "remark" : ""}>
							{row[col.name]}
						</div>
					);
				} else if (Object?.keys(row)?.includes("url") && col.name === "url") {
					return (
						<div style={row[col.name] !== "N/A" ? { textTransform: "lowercase" } : {}}>{row[col.name]}</div>
					);
				} else if (Object?.keys(row)?.includes("deviceDetails") && row.deviceDetails.length > 0) {
					return (
						<div data-tag="allowRowEvents" className="expandable__row">{row[col.name]}</div>
					);
				} else if (col.name === "site_display_name") {
					return <div data-tag="allowRowEvents" className="expandable__row">{row[col.name] ? row[col?.name]: row?.siteName}</div>;
				}else if (row[col.name]) {
					return <div data-tag="allowRowEvents" className="expandable__row">{row[col.name]}</div>;
				} else {
					return <div>-- </div>;
				}
			},
			sortable: col?.sortable,
			wrap: true,
			maxWidth: col?.maxWidth,
			width: col?.width ? col?.width : "auto",
			sortField: col?.sortField,
			isEnabled: col?.isEnabled,
			minWidth: col?.minWidth,
		});
	}
	return cols;
};


export const customSort = (column, direction, data) => {
	const sortedData = [...data]; // Create a shallow copy of the data array

	sortedData.sort((a, b) => {
		const valueA = a[column.id];
		const valueB = b[column.id];
		

		if (valueA && valueB) {
			if (direction === "asc") {
				if (typeof valueA === "string" && typeof valueB === "string") {
					return valueA.localeCompare(valueB);
				} else {
					return valueA - valueB;
				}
			} else {
				if (typeof valueB === "string" && typeof valueA === "string") {
					return valueB.localeCompare(valueA);
				} else {
					return valueB - valueA;
				}
			}
		} else {
			return "";
		}
	});
	return sortedData;
};

export const sortStatus = (col, direction, data) => {
	let sortedData = [...data]
	var ordering = {}, // map for efficient lookup of sortIndex
		sortOrder = ['down', 'maint', 'up', "Disconnected"];
	for (var i = 0; i < sortOrder.length; i++)
		ordering[sortOrder[i]] = i;
	sortedData.sort(function (a, b) {
		return (ordering[a.link_status] - ordering[b.link_status]);
	});
	sortedData = sortedData.sort((x, y) =>  Number(y?.is_bounced) - Number(x?.is_bounced))
	return sortedData
}

export const customSearch = (keywords, data) => {
	const search = keywords ? keywords?.toLowerCase() : '';
	const matchingRows = [];
	for (let i = 0; i < data.length; i++) {
		const row = data[i];
		for (const key in row) {
			if (!!Object.getOwnPropertyDescriptor(row, key) && typeof row[key] === "string" && row[key].toLowerCase().includes(search)) {
				matchingRows.push(row);
				break;
			}
		}
	}
	return matchingRows;
}

export const downloadFileFromAPI = async (data, fileName) => {
	const currentDatetime = moment(new Date()).format('MM-DD-YYYY_hh-MM-SS_A')
	const nameOfFile = fileName + " " + currentDatetime  + ".csv"

	try {

		const url = window.URL.createObjectURL(new Blob([data]));
		const a = document.createElement('a');
		a.href = url;
		a.download = nameOfFile;
		a.click();

		window.URL.revokeObjectURL(url);
		toast.success("File Downloaded Successfully")

	} catch (error) {
		toast.error(`Error in exporting data`);

	}
}

export const trimSpacesFromStartAndEnd = (str) => {
		if (!str) return '';
		return str?.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
	
}
