import { Badge, styled } from "@mui/material"

export const ticketSalesForceSideBarDetails = [
    {
        id: 1,
        valueKey: "priority",
        title: "Priority",
        type: "Priority",
        isEnabled: true,
    },
    {
        id: 3,
        valueKey: "status",
        title: "Status",
        type: "Status",
        isEnabled: true,
    },
    {
        id: 14,
        valueKey: "created_date",
        title: "Created Date",
        type: "Date",
        isEnabled: true,
    },
    {
        id: 9,
        // Case owner will be the assignee of the ticket
        valueKey: "owner_name",
        title: "Assigned To",
        isEnabled: true,
    },
    {
        id: 8,
        valueKey: "created_by_name",
        title: "Created By",
        isEnabled: true,

    },
    {
        id: 20,
        valueKey: "account_name",
        title: "Account Name",
        isEnabled: true,
    },

    {
        id: 2,
        valueKey: "severity",
        title: "Severity",
        isEnabled: true,
    },
    {
        id: 7,
        valueKey: "last_modified_by_name",
        title: "Modified By",
        isEnabled: true,
    },
    {
        id: 12,
        valueKey: "last_modified_time",
        title: "Last Modified Time",
        type: "Date",
        isEnabled: true,
    },
    // {
    //     id: 15,
    //     valueKey: "ticket_source",
    //     title: "Ticket Source",
    //     type: "Ticket Source",
    //     isEnabled: true,
    // },
    {
        id: 5,
        valueKey: "contact_email",
        title: "Contact Email",
        isEnabled: true,
    },


    {
        id: 11,
        valueKey: "closed_date",
        title: "Closed Date",
        type: "Date",
        isEnabled: true,
    },
]


export const ticketServiceNowSideBarDetails = [
    {
        id: 2,
        valueKey: "priority",
        title: "Priority",
        type: "Priority"
    },
    {
        id: 2,
        valueKey: "state",
        title: "Status",
        type: "Status"
    },
    {
        id: 1,
        valueKey: "category",
        title: "Category"
    },
    {
        id: 16,
        valueKey: "opened_by.display_value",
        title: "Opened By",
    },
    {
        id: 17,
        valueKey: "impact",
        title: "Impact",
        type: "CleanString"
    },
    {
        id: 13,
        valueKey: "assigned_to",
        title: "Assigned To",
    },
    {
        id: 14,
        valueKey: "upon_reject",
        title: "Upon Reject",
    },
    {
        id: 3,
        valueKey: "parent_incident",
        title: "Parent Id"
    },
    {
        id: 7,
        valueKey: "sys_class_name",
        title: "System Class Name"
    },
    {
        id: 4,
        valueKey: "opened_at",
        title: "Opened At",
        type: "Date"
    },
    {
        id: 12,
        valueKey: "sys_created_on",
        title: "Created Date",
        type: "Date"
    },

    {
        id: 5,
        valueKey: "expected_start",
        title: "Expected Start",
        type: "Date"
    },
    {
        id: 11,
        valueKey: "due_date",
        title: "Due Date",
        type: "Date"
    },
    {
        id: 6,
        valueKey: "resolved_at",
        title: "Resolved At"
    },

    {
        id: 8,
        valueKey: "reopened_time",
        title: "Reopened Time",
        type: "Date"
    },
    {
        id: 9,
        valueKey: "closed_at",
        title: "Closed Time",
        type: "Date"
    },
    {
        id: 10,
        valueKey: "closed_by",
        title: "Closed By",
    },
]

// REMINDER:
// comments public work_notes private
// type as 1 for public and 2 for private comments
export const typeMapper = {
    work_notes: 2,
    comments: 1
}

export const getPriorityStyles = (priority) => {
    switch (priority) {
        case 'p1':
            // Red
            return {
                textColor: '#fff',
                bgColor: '#B8001F',
            };
        case 'p2':
            // orange
            return {
                textColor: '#fff',
                bgColor: '#FF8225',
            };
        case 'p3':
            // yellow
            return {
                textColor: '#fff',
                bgColor: '#fbbd00',
            };
        case 'p4':
            // Green
            return {
                textColor: '#fff',
                bgColor: '#008000',
            };
        default:
            return {
                textColor: '#1E201E', // Default text color
                bgColor: '#B5C0D0', // Default background color
            };
    }
};


export const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
        case 'new':
            return '#d53b3b';
        // Gray
        case 'closed':
        case 'solved':
        case 'completed':
            return '#117554';
        // Blue
        case 'in progress':
            return '#244C91';
        // red
        case 'on hold':
        case 'on hold - sangoma':
            return '#B8001F';
        // Orange
        case 'resolved':
            return '#FB773C';
        case 'canceled':
            return '#FEFDED';
        case 'feedback':
        case 'researching':
        case 'referred to contact sales form':
        case 'waiting on customer':
        case 'rma':
        case 'waiting on carrier':
        case 'dispatch':
        case 'monitor':
            return '#dddddd';
        // Green
        case 'open':
            return '#00a134';
        case 'escalated':
            return '#931A25';
        default:
            return '#B5C0D0'; // Default color
    }
}

export const getStatusTextColor = (status) => {
    switch (status.toLowerCase()) {
        case 'new':
        case 'closed':
        case 'solved':
        case 'open':
        case 'escalated':
        case 'completed':
        case 'in progress':
        case 'on hold':
        case 'on hold - sangoma':
            return '#fff';   
        case 'resolved':
        case 'canceled':
        case 'feedback':
        case 'researching':
        case 'referred to contact sales form':
        case 'waiting on customer':
        case 'rma':
        case 'waiting on carrier':
        case 'dispatch':
        case 'monitor':
            return '#000';
        default:
            return '#1E201E'; // Default text color
    }
}


export const ServiceNowTicketStatusOptionsList = [
    {
        value: "all",
        label: "All",
    },
    {
        value: "new",
        label: "Open",
    },
    {
        value: "closed",
        label: "Closed",
    },
    {
        value: "in progress",
        label: "In Progress",
    },
    {
        value: "on hold",
        label: "On Hold",
    },
    {
        value: "resolved",
        label: "Resolved",
    },
    {
        value: "canceled",
        label: "Canceled",
    }
];

export const SalesforceTicketStatusOptionsList = [
    {
        value: "all",
        label: "All",
    },
    {
        value: "new",
        label: "New",
    },
    {
        value: "open",
        label: "Open",
    },
    {
        value: "waiting on customer",
        label: "Waiting on Customer",
    },
    {
        value: "escalated",
        label: "Escalated",
    },
    {
        value: "rma",
        label: "RMA",
    },
    {
        value: "solved",
        label: "Solved",
    },
    {
        value: "waiting on carrier",
        label: "Waiting on Carrier",
    },
    {
        value: "dispatch",
        label: "Dispatch",
    },
    {
        value: "monitor",
        label: "Monitor",
    },
    {
        value: "closed",
        label: "Closed",
    }

];

export const ticketSystemBrandColorMapping = {
    salesforceTickets: {
        bgColor: "#00A1E0",
        textColor: "#fff"
    },
    serviceNowTickets: {
        bgColor: "#81B5A1",
        textColor: "#293E40"
    }
}

export const StyledBadge = styled(Badge)(() => ({

    '& .MuiBadge-badge': {
        right: 6,
        top: 6,
        height: 10,
        width: 10,
        padding: '0 4px',
        background: '#F4CE14'
    },
}));

export const cleanString = (str) => {
    if (!str) return '';
    return str.toString().replace(/[0-9\s\W]/g, '');
};

export const ticketCardConstants = {
    OPEN: 'Open',
    NEW: 'New',
    RECENTLY_OPEN: 'recently_open',
    CLOSED: 'Closed',
    ESCALATED: 'Escalated',
    IN_PROGRESS: 'In Progress',
    OTHER: 'Other'
}

export const ticketDetails = (ticketStatusResponse, filterValue, options) => {

    const enableCards = (value) => {
        let enableFilter = true;
    
        if (filterValue && filterValue.length > 0) {
            // if (value === ticketCardConstants['RECENTLY_OPEN'] || value === ticketCardConstants['OPEN']) {
            //     enableFilter = filterValue.includes(ticketCardConstants['NEW']) || 
            //                    filterValue.includes(ticketCardConstants['OPEN']);
            // } else 
            if (value === ticketCardConstants['OTHER']) {
                const dontIncludeData = [
                    ticketCardConstants['OPEN'],
                    ticketCardConstants['ESCALATED'],
                    ticketCardConstants['NEW'],
                    ticketCardConstants['IN_PROGRESS'],
                    ticketCardConstants['CLOSED']
                ];
    
                // Filter options excluding dontIncludeData
                let otherOptions = options.filter(option => !dontIncludeData.includes(option.value));
                
                // Check if all other options are in the filterValue array
                enableFilter = otherOptions.some(option => filterValue.includes(option.value));
    
            } else {
                enableFilter = filterValue.includes(value);
            }
        }else if(value === ticketCardConstants['CLOSED']){
            enableFilter = false;
        }
    
        return enableFilter;
    };
    
    
    // if (!ticketStatusResponse) return [];
    return [
        {
            id: ticketCardConstants['OPEN'],
            text: 'Total Open',
            count: Number(ticketStatusResponse?.['open']?.total_count || 0),
            percentage: Number(ticketStatusResponse?.['open']?.percentage || 0),
            className: 'ticket__card__open',
            isEnabled: enableCards(ticketCardConstants['OPEN']),
            tooltipText:'This category includes tickets that are currently open.'
        },
        {
            id: ticketCardConstants['NEW'],
            text: 'Total New',
            count: ticketStatusResponse?.['new']?.total_count || 0,
            percentage: ticketStatusResponse?.['new']?.percentage || 0,
            className: 'link__monitor__card__recently_open',
            isEnabled: enableCards(ticketCardConstants['NEW']),
            tooltipText:'This category includes tickets that have been newly created.'
        },
        // {
        //     id: ticketCardConstants['RECENTLY_OPEN'],
        //     text: 'Total Open (last 24h)',
        //     count: ticketStatusResponse?.['open in last 24 hours']?.total_count || 0,
        //     percentage: ticketStatusResponse?.['open in last 24 hours']?.percentage || 0,
        //     className: 'link__monitor__card__recently_open',
        //     isEnabled: enableCards(ticketCardConstants['RECENTLY_OPEN']),
        //     disableClick: true,
        //     tooltipText:'This category includes tickets that are newly created within the last 24 hours.'
        // },
        {
            id: ticketCardConstants['IN_PROGRESS'],
            text: 'Total In Progress',
            count: ticketStatusResponse?.['in progress']?.total_count || 0,
            percentage: ticketStatusResponse?.['in progress']?.percentage || 0,
            className: 'link__monitor__card__in_progress',
            isEnabled: enableCards(ticketCardConstants['IN_PROGRESS']),
            tooltipText:'Tickets in this category are actively being worked on and have the status "In Progress."'
        },
        {
            id: ticketCardConstants['ESCALATED'],
            text: 'Total Escalated',
            count: ticketStatusResponse?.['escalated']?.total_count || 0,
            percentage: ticketStatusResponse?.['escalated']?.percentage || 0,
            className: 'link__monitor__card__escalated',
            isEnabled: enableCards(ticketCardConstants['ESCALATED']),
            tooltipText: 'This category includes tickets that require additional attention or priority handling.'
        },
        {
            id: ticketCardConstants['CLOSED'],
            text: 'Total Closed',
            count: ticketStatusResponse?.['closed']?.total_count || 0,
            percentage: ticketStatusResponse?.['closed']?.percentage || 0,
            className: 'link__monitor__card__closed',
            isEnabled: enableCards(ticketCardConstants['CLOSED']),
            tooltipText: 'This category includes tickets that have been closed.'
        },
        {
            id: ticketCardConstants['OTHER'],
            text: 'Total Misc State',
            count: ticketStatusResponse?.['other']?.total_count || 0,
            percentage: ticketStatusResponse?.['other']?.percentage || 0,
            className: 'link__monitor__card__na',
            isEnabled: enableCards(ticketCardConstants['OTHER']),
            tooltipText:'This category captures tickets with various statuses that do not fall under open, new, in-progress, escalated and closed.'
        },

    ]
}
