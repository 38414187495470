import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SpeedDial from "@mui/material/SpeedDial";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import useWindowHeight from "components/common/useWindowSize";
import { formTypes } from "constant/Formtypes";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCanAccess } from "services/canACLAccess";
import NetopsHeader from "./NetopsHeader";
import {ReactComponent as NetopsSidebarArrow} from "../netopsStyles/assets/sidebar/arrow.svg";
import {ReactComponent as Chevron} from "../netopsStyles/assets/chevron.svg"
import {ReactComponent as NetopsSuppportIcon} from "../netopsStyles/assets/sidebar/support.svg"


const drawerWidth = 321;
const ticketTenantIds = process.env.REACT_APP_TICKET_TENANT_IDS

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: "52px",
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function NetopsSidebar({ open, setOpen }) {
	const user = useSelector((state) => state.user);
	const location = useLocation();
	const { pathname } = location;
	const navigate = useNavigate();
	const windowHeight = useWindowHeight();
	const canAccess = useCanAccess();

	const [startIndex, setStartIndex] = useState(0);
	const [visibleItems, setVisibleItems] = useState(7);
	const [showCarousel, setShowCarousel] = useState(true);

	const isActive = (link) => {
		if (link?.includes(pathname)) {
			return true;
		}
		return false;
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (user?.tenant?.name && user?.tenant?.name !== 'Sangoma' && (pathname === "/nf-status/tenants" || pathname === "/nf-status/service" || pathname === "/nf-status/tenants/changelog" || (user.userRole == 'admin' && pathname == "/nf-status/user-management"))) {
			navigate("/")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant])

	const changeLocation = (url) => {
		if (pathname !== url) {
			navigate(url, { replace: true });
		} else {
			navigate(url, { replace: true });
			window.location.reload();
		}
	}

	const menuList = (text) => {
		return (
			<Box className={"list_item_container"}>
				<Link
					className={`list_item flex `}
					to={`/nf-status/${text.category}`}
					onClick={() => changeLocation(`/nf-status/${text.category}`)}
				>
					<Tooltip title={text.title} placement="right-end">
						<ListItemIcon color={user?.themePreference === 'dark' ? '#fff' : ''} sx={{ margin: !open ? "12px" : '' }} className="side__bar__icon ">
							{ text?.netopsIcon ? text?.netopsIcon : null}
						</ListItemIcon>
					</Tooltip>
					<ListItemText className="text" primary={text.title} />
				</Link>
			</Box>
		);
	};

	const sortedFormTypes = useCallback(() => {
		return formTypes.sort((a, b) => { return a.orderIndex - b.orderIndex });
	}, [showCarousel])

	const renderListItem = (text, index) => {
		if ((text.resources.length <= 0 || text.resources.filter(res => canAccess('read', res)).length)) {
			return (
				<ListItem
					className={`${isActive(text?.activeLink) ? "active" : ""} sidebar__item`} key={index} disablePadding>
					{menuList(text)}
				</ListItem>
			);
		}
		return null;
	};

	const shouldRenderItem = (text) => {
		const userRole = user?.userRole;
		const tenantName = user?.tenant?.name;
		let renderItem = true

		if (tenantName !== 'Sangoma') {
			if (userRole === 'user') {
				renderItem = text?.category !== "tenants" && text?.category !== "service";
			}
			if (userRole === 'admin') {
				renderItem = text?.category !== "tenants" && text?.category !== "service" && text?.category !== "user-management";
			}
		}

		if (text?.category === "tickets") {
			renderItem = (renderItem && user?.email?.toLowerCase()?.includes("sangoma.com")) || (renderItem && ticketTenantIds?.includes(user?.tenant?.id));
		}
		
		if(text?.category === "circuit-link-monitoring") {
			renderItem = (renderItem && user?.email?.toLowerCase()?.includes("sangoma.com"));		
		}
		return renderItem;
	};

	const handleScrollUp = () => {
		setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
	};

	const handleScrollDown = () => {
		setStartIndex((prevIndex) => Math.min(prevIndex + 1, sortedFormTypes().length - visibleItems));
	};

	const buttonStyles = (disabled) => ({
		opacity: disabled ? "0.4" : "",
		pointerEvents: disabled ? "none" : "",
		cursor: disabled ? "default" : ""
	});


	useEffect(() => {
		if (user?.tenant?.name !== 'Sangoma' && (pathname === "/nf-status/tenants" || pathname === "/nf-status/service" || pathname === "/nf-status/tenants/changelog" || (user.userRole == 'admin' && pathname == "/nf-status/user-management"))) {
			navigate("/")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant])

	useEffect(() => {
		let filteredItems = sortedFormTypes().filter(text => shouldRenderItem(text));
		// Total Height - (Header + Height of Carousel Buttons)
		const avaiableHeightForSideBar = windowHeight?.height - (48 + 24 + 24 + 16)
		const itemsByHeight = Math.round(avaiableHeightForSideBar / 40);
		if (filteredItems?.length <= itemsByHeight) {
			setShowCarousel(false)
			setVisibleItems(filteredItems?.length);
			setStartIndex(0)
		} else {
			setShowCarousel(true)
			setVisibleItems(itemsByHeight);
		}

	}, [windowHeight, formTypes, user?.tenant?.id]);

	const renderMenuItems = () => {
		let filteredItems = sortedFormTypes().filter(text => shouldRenderItem(text));
		let availableItems = filteredItems?.slice(startIndex, startIndex + visibleItems);
		return availableItems.map((text, index) => renderListItem(text, index));
	};

	return (
		<>
			<NetopsHeader />
			<Box
				open={open}
				onClick={open ? handleDrawerClose : handleDrawerOpen}
			>
				<SpeedDial
					ariaLabel="open Drawer"
					sx={
						open
							? { position: "absolute", top: 65, left: 300 }
							: { position: "absolute", top: 65, left: 33 }
					}

					className={`${user?.themePreference === "dark" ? "dark__mode" : ""}  sidebar___drawer  `}
					icon={
						open ? (
							<Chevron className="chevron" />
						) : (
							<Chevron style={{ transform: "rotate(180deg)" }} className="chevron" />
						)
					}
					direction="right"
				/>
			</Box>
			<Drawer
				className={`${user?.themePreference === "dark" ? "dark__mode" : ""}  netops__sidebar  `}
				open={open}
				variant="permanent"
				anchor="left"
				PaperProps={{
					sx: {
						marginTop: '48px',
					}
				}}
			>
				{showCarousel && <div style={{ ...buttonStyles(startIndex === 0), }} className="sidebar__carousel__button__wrap" onClick={handleScrollUp} >
					<NetopsSidebarArrow style={{ marginTop: "4px", transform: "rotate(180deg)" }} className="sidebar__carousel__button" />
				

				</div>}
				<List key={showCarousel + user?.tenant?.id} className="list" sx={{ padding: "8px" }}>
					{renderMenuItems()}
					{(!user?.email?.toLowerCase()?.includes("sangoma.com") && !ticketTenantIds?.includes(user?.tenant?.id)) &&
						<ListItem className={` sidebar__item`} disablePadding>

							<Box className={"list_item_container"}>
								<a className="list_item flex" target="_blank" href="https://help.sangoma.com/" rel="noreferrer">
									<Tooltip title={'Sangoma Help'} placement="right-end">
										<ListItemIcon color={user?.themePreference === 'dark' ? '#fff' : ''} className="side__bar__icon ">
											<NetopsSuppportIcon style={{ scale: '0.8' }} className="icon" />

										</ListItemIcon>
									</Tooltip>
									<ListItemText className="text" primary={'Sangoma Help'} />
								</a>
							</Box>
						</ListItem>
					}
				</List>
				{showCarousel && <div style={{ ...buttonStyles(startIndex + visibleItems >= formTypes.length), }} className="sidebar__carousel__button__wrap" onClick={handleScrollDown} >
				<NetopsSidebarArrow style={{ marginBottom: "4px", }} className="sidebar__carousel__button" />
				</div>
				}
			</Drawer>
		</>
	);
}
