import React, { useEffect,  useState } from 'react'
import SelectDropDown from 'components/common/SelectDropdown';
import { fetchMappingData } from "store/binding";
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import Grid from "@mui/material/Grid";
import MappedEditPopup from './MappedEditPopup';
import './binding.css'
import { useDispatch,useSelector } from 'react-redux';
import TableComponent from 'components/common/TableComponent/TableComponent';
import expotIcon from '../../../assets/images/export-excel.png'
import SearchInputField from 'components/common/SearchInputField';
import { Box } from '@mui/material';
import GlobalExportButton from 'components/common/GlobalExportButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCanAccess } from 'services/canACLAccess';
import { toast } from 'react-toastify';

export default function MappedBinding() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const userTenant = user?.tenant;
    const tenants = useSelector((state) => state.devices?.tenants?.map((tenant) => {
        return {
            value: tenant?.id,
            label: tenant?.name,
            custom_fields: tenant?.custom_fields,
        }}))
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [row, setRow] = useState();
    const [refresKey, setRefreshkey] = useState(null)
    const [showLoader, setShowLoader] = useState(false);
    const [offset, setOffset] = useState(1);
    const [searchTextState, setSearchTextState] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedTenant, setSelectedTenant] = useState(null);
	const canAccess = useCanAccess();


    useEffect(() => {
        setSearchTextState(null);
        setSelectedTenant(null);
        setRows([])
        if(user?.tenant?.id || selectedTenant?.value){
            getSitesData(null, '', null);
            return;
        }
    },[user?.tenant])

    const onChangeTenant = async(val) => {
        setSelectedTenant(val);
        setSearchTextState('');
        getSitesData(null, null, val?.value);
    }


    const formatData = (rowsData) => {
        let formatedData = [];

        rowsData?.map(item => {
            let rowData = {}
            let device = item?.deviceData;
            let wifiOptions = [];
            let cradlepointOptions = [];
            let sdwanOptions = [];
            let fortinet = [];
            let circuits = [];
            device?.map(dev => {
                let services = dev?.custom_fields
                    if(services.vco_edge_id)
                        sdwanOptions.push({value : dev?.id , label: dev?.name});
                    if(services.cmeraki_network_id)
                        wifiOptions.push({value : dev?.id , label: dev?.name, meraki_network_id:dev.custom_fields?.cmeraki_network_id
                        });
                    if(services.wug_ndevice_id || services.circuit_name)
                        circuits.push({value : dev?.id , label: dev?.name});
                    if(services.cradlepoint_router_id)
                        cradlepointOptions.push({value : dev?.id , label: dev?.name});
                    if(services.fortinet_device_oid || services?.fortinet_public_ip)
                        fortinet.push({value : dev?.id , label: dev?.name, fotinetCustomIp: !services.fortinet_device_oid? services?.fortinet_public_ip: ""});
            })
            rowData['wifiOptions'] = wifiOptions;
            rowData['cradlepointOptions'] = cradlepointOptions;
            rowData['sdwanOptions'] = sdwanOptions;
            rowData ['fortinet'] = fortinet;
            rowData ['circuits'] = circuits;
            rowData['id'] = item?.id;
            rowData['site_name'] = item?.site_name;
            rowData['site_display_name'] = item?.site_display_name;
            formatedData.push(rowData)
        })
        return formatedData;
    }


    const getSitesData = async (pagination, searchTxt, id) => {
        setIsLoading(true)
        if(pagination?.offset){
            setOffset(pagination.offset)
        }
        pagination  = pagination ? {...pagination} : {offset: offset , limit : 20, sortField: 'site_display_name', sortOrder: 'asc'};
        searchTxt = searchTxt === '' ? searchTxt : searchTxt? searchTxt : searchTextState;
        let req = {id : id ? id : user?.tenant?.id? user?.tenant?.id : selectedTenant?.value, searchText : searchTxt , ...pagination}
        try{
            let data = await dispatch(fetchMappingData(req)).unwrap();
            let formatedData = formatData(data?.data);
            setRows(formatedData);
            setTotalCount(data?.count);
        }
        catch (error) {
			toast.error("Oops! Something went wrong");
			console.error("Error fetching tenants data:", error);
			// Handle the error appropriately, such as showing an error message to the user
		} finally {
            setIsLoading(false);
            setShowLoader(false);
            setRefreshkey(Math.random() + new Date()); // End loading state
		}
       
    };

    const onEditClick = (row) => {
        setShowEditPopup(true);
        setRow(row);
    }


    const getFieldVal = (fieldName) => {
        if(selectedTenant?.value) {
            return selectedTenant?.custom_fields?.[fieldName]
        }
        return userTenant?.custom_fields?.[fieldName];
    }

    const showActionField = (row) => {
        if(row?.sdwanOptions?.length > 0 || row?.cradlepointOptions?.length > 0 ||row?.circuits?.length > 0 ||row?.wifiOptions?.length > 0 ||
            row?.fortinet?.length > 0){
                return true;
            }
        else{
            return false;
        }

    }


    const headers = [
        { name: 'S. No',  title: 'S. No', width: '10px', selector: (row, index) => (index + 1), centre: true, isEnabled: true },
        { 
            name: `Site Display Name`, 
            title: `Site Display Name`,
            sortField: 'site_display_name', 
            minWidth: 200, 
            isEnabled: true, 
            sortable: true, 
            selector: (row) => {
                return (
                    <TooltipLabel text={row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : '-'} />
                )
            }
        },
        {
            name: `Site Name`, 
            title: `Site Name`, 
            sortField: 'site_name', 
            minWidth: 200, 
            isEnabled: false, 
            sortable: false, 
            selector: (row) => (
                <TooltipLabel text={row?.site_name} />
            )
        },
        { name: 'Sdwan', title: 'Sdwan', minWidth: 200, maxWidth: '300px', isEnabled: getFieldVal('vco_enterprise_id'), selector: (row) => { return (<SelectDropDown menuPortalTarget={document.body} styles={customStyle} options={row?.sdwanOptions} value={row?.sdwanOptions?.length > 0 ? row?.sdwanOptions[0] : {}} placeholder={' '} hideFilterIcon={true} />) } },
        { name: 'Wifi',  title: 'Wifi', minWidth: 200, maxWidth: '300px', isEnabled: getFieldVal('meraki_org_id'), selector: (row) => (<SelectDropDown key="21345678" menuPortalTarget={document.body} styles={customStyle} options={row?.wifiOptions} value={row?.wifiOptions?.length > 0 ? row?.wifiOptions[0] : {}} hideFilterIcon={true} placeholder={' '} />) },
        { name: 'Circuits', title: 'Circuits', minWidth: 200, maxWidth: '300px', isEnabled: getFieldVal('wug_device_group_id'), selector: (row) =>  {
            const isCircuitEmpty = row?.circuits?.length === 0;
            const hasCradlepointOrFortinet = row?.cradlepointOptions?.length > 0 || row?.fortinet?.length > 0;
            
            return (
                <SelectDropDown 
                    menuPortalTarget={document.body} 
                    options={row?.circuits} 
                    value={row?.circuits?.length > 0 ? row?.circuits[0] : null} 
                    hideFilterIcon={true} 
                    placeholder={' '} 
                    required = {isCircuitEmpty && hasCradlepointOrFortinet}
                />
            );
        }},
        { name: 'CradlePoint',  title: 'CradlePoint', minWidth: 200, maxWidth: '300px', isEnabled: getFieldVal('cradlepoint_id'), selector: (row) => (<SelectDropDown menuPortalTarget={document.body} styles={customStyle} options={row?.cradlepointOptions} value={row?.cradlepointOptions?.length > 0 ? row?.cradlepointOptions[0] : {}} placeholder={' '} hideFilterIcon={true} />) },
        { name: 'Fortinet',  title: 'Fortinet', minWidth: 200, maxWidth: '300px', isEnabled: getFieldVal('fortinet_adom_oid'), selector: (row) => (<SelectDropDown menuPortalTarget={document.body} styles={customStyle} options={row?.fortinet} hideFilterIcon={true} value={row?.fortinet?.length > 0 ? row?.fortinet[0] : {}} placeholder={' '} />) },
        {
            name: 'Action',
            title: 'Action',
            maxWidth: '75px',
            isEnabled: canAccess( 'update', 'binding'),
            selector: (row) => (
                <div className={`flex ${showActionField(row) ? 'mapping-action-cell' : 'disable-action-cell'} `}>
                    <FontAwesomeIcon icon="fa-solid fa-pencil" style={{ marginRight: '5px' }} onClick={() => onEditClick(row)} />
                    <GlobalExportButton
                        exportApiRequest={
                            {
                                tenantId: selectedTenant?.value ? selectedTenant?.value : user?.tenant?.id,
                                sid: row?.id, filetype: 'csv', dataOf: 'devicemapping',
                            }
                        }

                        heading={`Mapped Binding Devices`}
                        text={"Are you sure you want to download mapped devices list ?"}
                        fileName={"devicemapping_data"}
                        customExportButton={<img height={20} width={20} src={expotIcon}></img>}
                    />
                </div>
            )
        }
    ]


  

    const handleClosePopup = (callApi) => {
        setRow(null);
        setShowEditPopup(false)
        if(callApi === 'callApi'){
            getSitesData();
        }
    }

    const handleSearch = (e) => {
		if(user?.tenant?.id || selectedTenant?.value){
            if (!e.target.value) {
			setSearchTextState(null);
            getSitesData();
		} else {
			getSitesData(null, e.target.value);
			setSearchTextState(e.target.value);
		}
    }
	};
    return (
        <Box className={user?.themePreference === "dark" ? " map__filtered__wrapper__darkmode flex-col " :"flex-col"} >
            <div className='mapped__filter__wrap'>
            <Grid item md={3} className="filter_wrap">
             <SearchInputField handleClick={handleSearch} key={selectedTenant?.value} debounceProp={user?.tenant?.id}/>
            </Grid>
            <Grid item md={3} className="search_filter_wrap">
            {user?.tenant?.name === 'Sangoma' && <SelectDropDown options={tenants?.filter(i => i.label !== 'Sangoma')} styles = {customTenantStyle}
                    value={selectedTenant} placeholder={'Select Tenant'} hideFilterIcon={true} onChange={onChangeTenant} />}    
                           {(user?.tenant?.name !== 'Sangoma' || selectedTenant?.value) && <GlobalExportButton
                            enableFilteredDataExportButton={searchTextState ? true : false}
                            exportApiRequest={{
                                filetype: 'csv',
                                dataOf: 'allMappedDeviceData',
                                tenantId: user?.tenant?.name === 'Sangoma'? selectedTenant?.value : user?.tenant?.id,
                                searchText: searchTextState || null,
                                custom_fields: JSON.stringify(selectedTenant?.custom_fields || userTenant?.custom_fields),
                            }}
                            fileName={`MapData`}
                            text={"Are you sure you want to download mapped devices ?"}
                            heading={"Mapped Devices"}
                        />}
            </Grid>
            </div>
            <TableComponent
                className="mapped_table"
                columns={headers}
                rows={rows}
                offset = {offset}
                showLoader = {showLoader}
                keyId = {refresKey}
                maxHeight = {'calc(100vh - 310px'}
                isLoading={isLoading}
                onPageChange = {getSitesData}
                totalRows = {totalCount}
                defaultSortOrder={"asc"}
                defaultSortField={"site_display_name"}
            />
        {showEditPopup && <MappedEditPopup data = {row} handleClosePopup = {handleClosePopup} adminTenant = {user?.tenant?.name === 'Sangoma'? selectedTenant?.value : null}/>}
 
        </Box>
    )
}

const customStyle = {
    option: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    valueContainer: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    container: (base, state) => ({
        ...base,
        minWidth: '100%',
        maxWidth: '100%',
        pointerEvents: state?.options?.length > 0 ? 'default' :'none',
       
    }),
    control: (base) => ({
        ...base,
        cursor : 'pointer !important'
    }),
}


const customTenantStyle = {
    option: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    valueContainer: (base) => ({
        ...base,
        textTransform: 'capitalize',
    }),
    container: (base) => ({
        ...base,
        minWidth: '250px',
    })
}
