import { Box } from '@mui/material'
import { cleanString, getPriorityStyles } from './ticketsConstants'

const PriorityComponent = ({priority, ticketingSystem}) => {
  if(priority) {
    return (
      <Box sx={{ display: "flex" }}>
      <Box className='priority' sx={{ backgroundColor: getPriorityStyles(priority?.toLowerCase())?.bgColor, color: getPriorityStyles(priority?.toLowerCase())?.textColor }}>
        {ticketingSystem === "servicenow" ? cleanString(priority) : priority}
      </Box>
    </Box>
    )
  } else{
    return '--'
  }

}

export default PriorityComponent
