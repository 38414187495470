import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Divider, Typography } from '@mui/material'
import Header from 'components/common/modal/Header'
import { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import moment from 'moment-timezone';
import React from 'react'

const EmailBodyPopup = ({ data, onClose }) => {
  return (
    <Box className="email__popup__container" sx={{}}>
      <Header text="Email" close={onClose} />
      <Box sx={{ padding: "28px", maxHeight: "500px", overflow: "auto", }}>
        <Typography sx={{ fontSize: "20px", marginBottom: "12px", fontWeight: "600" }} component="p">
          {data?.Subject}
        </Typography>
        <Box className="email__body" sx={{ borderRadius: "4px", background: "#F5F5F7", border: "1px solid #e0e0e0", padding: "16px" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', fontSize: "14px", alignItems: "center" }}>
              <Avatar sx={{ bgcolor: "#45474B" }}>{data?.FromAddress?.substring(0, 1)?.toUpperCase()}</Avatar>
              <Box sx={{ paddingLeft: "12px", }}>
                <Typography sx={{ fontWeight: 600 }} component="p">
                  {data?.FromAddress}
                </Typography>
                <Typography component="p">
                  <StyledTooltip title={"To"} placement="top">
                    <FontAwesomeIcon style={{ fontSize: "14px", paddingRight: "4px", color: "#B4B4B8" }} icon="fa-solid fa-square-caret-down" />
                  </StyledTooltip>
                  {data?.ToAddress}
                </Typography>
              </Box>
            </Box>
            <Typography component="p">
              {moment(new Date(data?.MessageDate)).format('LLL')}
            </Typography>
          </Box>
          <Divider sx={{ padding: "10px" }} />

          {data?.TextBody && (
            <Typography className='email__content' component="p" sx={{ margin: "20px 0px", padding: "4px 0px", }}>
              {data.TextBody.split("\n").map((line, index, arr) => (
                <React.Fragment key={index}>
                  <Typography sx={{ fontSize: "14px", }} component={"p"}> {line}</Typography>
                  {index < arr.length - 1 && <br />}
                </React.Fragment>
              ))}
            </Typography>
          )}

        </Box>
      </Box>
    </Box>

  )
}

export default EmailBodyPopup
