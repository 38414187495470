import { FormHelperText } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

function InputGroup({
	label,
	id,
	value,
	setValues,
	classname,
	handleOnBlur,
	isRequired,
	placeholder,
	disable,
	maxChar,
	styles,
	error,
	helperText
}) {

	const inputRef = useRef(null);
	const user = useSelector((state) => state.user);

	useEffect(() => {
		let resizeObserverEntries = []

		const observer = new ResizeObserver((entries) => {
			resizeObserverEntries = entries
		})

		if (inputRef.current) observer.observe(inputRef.current)

		return () => {
			resizeObserverEntries.forEach((entry) => entry.target.remove())
			observer.disconnect()
		}
	}, [])

	
	return (
		<FormGroup className="form_group_wrap" style={styles}>
			<InputLabel className="input_label">
				{label}
				{isRequired ? <span className="astrick">*</span> : null}
			</InputLabel>
			<TextField
				id={id+"-search"}
				key={id+"-search"}
				type="text"
				ref={inputRef}
				onFocus={(e) => handleOnBlur(e, id)}
				className={`${classname} ${disable ? user.themePreference == 'dark' ? "grayTextField_dark" :'grayTextField' : ''} `}
				value={value}
				variant="outlined"
				disabled={disable}
				onChange={(e) => setValues(e, id)}
				placeholder={placeholder ? placeholder : ""}
				autoComplete="off"
				inputProps={{
					maxlength: maxChar,
				}}
			/>
			{helperText && <FormHelperText error={error}>{helperText}</FormHelperText>} 
		</FormGroup>
	);
}

InputGroup.propTypes = {
	label: PropTypes.string,
	key: PropTypes.number,
	id: PropTypes.string,
	value: PropTypes.string,
	setValues: PropTypes.func,
	classname: PropTypes.string,
	placeholder: PropTypes.string,
};

export default InputGroup;
