import { MuiThemedCircularProgress } from 'components/common/MuiThemedComponents'
import React from 'react'
import { Box, Typography } from '@mui/material'
import EmailCard from './EmailCard'

const EmailComponent = ({ isEmailLoading, allEmails, handleEmailListingScreen, handleEmailBodyPopup }) => {
    
    return (
        isEmailLoading ? <Box sx={{ minHeight: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <MuiThemedCircularProgress />
        </Box>
            :
            allEmails && allEmails?.length > 0 ?
                <Box>
                    <Box>
                        {allEmails?.map((element, index) => {
                            if ((index + 1) > 2) return
                            return <EmailCard handleEmailBodyPopup={handleEmailBodyPopup} email={element} key={index} />
                        })}
                    </Box>
                    {allEmails?.length > 2 &&
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingTop: "10px" }} onClick={(e) => {
                        e?.stopPropagation();
                        handleEmailListingScreen(true)
                    }} className='ticket_show_more'>
                       Show more
                    </Box>
                    }
                </Box>
                :
                <Box sx={{
                    padding: "20px", minHeight: 200, display: "flex", alignItems: "center", justifyContent: "center"
                }} >
                    <Typography>No Emails Available</Typography>
                </Box>
    )
}

export default EmailComponent
