/* eslint-disable no-debugger */
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SangomaLogo from "assets/images/sangoma-logo-white.png";
import { MuiThemedSwitch, ThemedReactSelect } from "components/common/MuiThemedComponents";
import { useAuth } from 'hooks/useAuth';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserTenantsData } from "store/devices";
import { setTenant, setThemePreference } from "store/user";
import { updateUserThemePreference } from "store/usermanagement";
import HeaderDropdown from "./HeaderDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const user = useSelector((state) => state.user);
	const devices = useSelector((state) => state.devices)
	const [anchorEl, setAnchorEl] = useState(null);
	const auth = useAuth();

	useEffect(() => {
		dispatch(fetchUserTenantsData(user));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRedirect = (url) => {
		window.open(url,'_blank');
	}

	// eslint-disable-next-line no-unused-vars
	// const handleNotifications = () => {
	// 	handleClose()
	// 	setTimeout(() => {
	// 		navigate('/nf-status/manage-notifications');
	// 	}, 200);
	
	// }
	const handleSelectChange = async (selectedVal) => {
		let tenantData = undefined;
		tenantData = devices?.tenants?.filter((ten) => ten.id === selectedVal.value);
		tenantData = tenantData && tenantData.length ? tenantData[0] : undefined;
		navigate(location.pathname, {state: null});
		await dispatch(setTenant(tenantData));
		
	};

	const stringAvatar = (name) => {
		const [firstName, lastName] = name.split(' ');

		const initials = `${firstName[0]}${lastName ? lastName[0] : ''}`;

		return {
			sx: {
				bgcolor: '#d1daeb',
				color: "#000"
			},
			children: initials,
		};
	};

	const changeTheme = useCallback(() => {
		const themePreference = user?.themePreference === 'light' ? 'dark' : 'light';
		dispatch(setThemePreference({
			themePreference: themePreference
		}))
		dispatch(updateUserThemePreference( {id:user?.id,theme: themePreference} ));
		
	}, [user?.themePreference])

	const urlMappers = {
		settings: process.env.REACT_APP_SETTING_URL,
		help:process.env.REACT_APP_HELP_CENTER_URL,
		termsOfUse: process.env.REACT_APP_TERMS_OF_USE_URL
	}


	const dropdownoptions = useMemo(() =>{
		return [
			{
				id: 1,
				title: user?.name,
				startIcon:<FontAwesomeIcon  style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}} fontSize={"14px"}  icon="fa-solid fa-user" />,
				addTooltip: true,
			},
			{
				id: 2,
				title:'My Account',
				onClick: () => handleRedirect(urlMappers['settings']),
				startIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}}  fontSize={"14px"} icon="fa-solid fa-gear" />,
				endIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}} fontSize={"14px"}  icon="fa-solid fa-arrow-up-right-from-square" />
			},
			// {
			// 	id: 3,
			// 	title:'Manage Notifications',
			// 	onClick: () => handleNotifications(),
			// 	startIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}}  fontSize={"14px"} icon="fa-solid fa-bell" />,
			// },
			{
				id: 4,
				title:'Help Center',
				onClick:  () => handleRedirect(urlMappers['help']),
				startIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}}  fontSize={"14px"} icon="fa-solid fa-circle-question" />,
				endIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}} fontSize={"14px"}  icon="fa-solid fa-arrow-up-right-from-square" />
			},
			{
				id: 5,
				title:'Sangoma T&Cs',
				onClick: () => handleRedirect(urlMappers['termsOfUse']),
				startIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}}  fontSize={"14px"} icon="fa-solid fa-clipboard-list" />,
				endIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}} fontSize={"14px"}  icon="fa-solid fa-arrow-up-right-from-square" />
			},
			{
				id: 5,
				title:'Logout',
				onClick: auth.logout,
				startIcon: <FontAwesomeIcon style={{color:user?.themePreference === 'dark' ? '':'rgb(51 65 77/ 1)', opacity: 0.8}} fontSize={"14px"} icon="fa-solid fa-arrow-right-from-bracket" />
			},
		]
	},[user, auth])

	return (
		<>
			<header className={user?.themePreference === 'dark' ? 'header__darkmode' : ''}>
				<div className="d-flex align-items-center" style={{display:'flex', justifyContent:'center'}}>
					<img className="mainLogo" width="125" height="auto" src={SangomaLogo} alt="Star2Star Logo" />
				</div>

				<div className="logoutArea">
				{user?.userRole === 'user' && (devices?.tenants && devices?.tenants?.length === 1 || !devices?.tenants )? null:
					<Box className="tenant_cont">
						<FormLabel className="label">Tenant</FormLabel>
						<ThemedReactSelect					
							onChange={(e) => handleSelectChange(e)}
							name={"tenant"}
							placeholder="Select"
							defaultValue={{ value: user.tenant?.id, label: user.tenant?.name }}
							value={{ value: user.tenant?.id, label: user.tenant?.name }}
							options={devices?.tenants?.map((tenant) => {
								return {
									value: tenant.id,
									label: tenant.name,
								}
							})}
							classNamePrefix={user?.themePreference=== 'dark' ? 'react__select__dark__mode-header' :"react-select"}
						/>
						
					</Box>
						}


					<MuiThemedSwitch checked={user?.themePreference === 'dark'? true: false} onChange={changeTheme} size="small" />
					<Stack direction="row" spacing={2}>
						<Avatar {...stringAvatar(user.name ? user.name : "NA")}
							id="basic-button"
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick} />
					</Stack>
					<HeaderDropdown anchorEl={anchorEl} dropdownoptions={dropdownoptions} handleClose={handleClose} />
				</div>
			</header>
		</>
	);
}

export default Header;
