import React from "react";
import { MuiThemedButton } from "./MuiThemedComponents";
import { useSelector } from "react-redux";

function ButtonLayout({
	type,
	text,
	buttonStyleType,
	icon,
	handleClick,
	isLoading,
	disabled,
	Iconstyle
}) {

	const user = useSelector(state => state?.user);
	
	return (
		<MuiThemedButton
			showLoader={isLoading}
			type={type}
			className={
				buttonStyleType === "outlined" && user?.themePreference !== "dark"
					? "button-outlined"
					: buttonStyleType === "filled" && user?.themePreference !== "dark"
						? "button-filled"
						: buttonStyleType === "filledMap" && user?.themePreference !== "dark" ? "button-filled-map" : "buttonDarkMode"
			}
			onClick={handleClick}
			variant={buttonStyleType === "filled" ? "contained" : "outlined"}
			size="small"
			disabled={disabled}
			sx={{ mr: 0 }}
		>
			{icon && <span style={Iconstyle}>{icon}</span>}
			{text}
		</MuiThemedButton>
	);
}

export default ButtonLayout;
