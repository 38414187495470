import { Box } from '@mui/material';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import { getStatusColor, getStatusTextColor } from './ticketsConstants';
import TicketsComponent from './TicketsComponent';

export const getticketColumn = (previousScreen, previousScreenURL) => { 
  return [
    {
      cell: (row) => {
        let linkMonitorTickets = {
					salesforceTickets: [
						{

							"ticket_id": row?.ticket_id,
							"status": row?.ticket_status,
							"case_number": row?.ticket_case_number,
							"ticketType": 'salesforceTickets',
							"is_ticket_viewed": row?.is_ticket_viewed,
						}
					]
				}
        const tickets = previousScreenURL === 'link-monitoring' ? row?.ticket_case_number ? linkMonitorTickets : [] : row?.tickets
        return <TicketsComponent previousScreen={previousScreen} tenantName={row?.tenant?.display} tenantId={row?.tenant?.id} previousScreenURL={previousScreenURL} tickets={tickets} uniqueId={row?.id} key={row?.id} />
      },
      name: "Tickets",
      title: "Tickets",
      sortable: false,
      wrap: false,
      minWidth: '220px !important',
      isEnabled: false,
    },
  ]
}

const TicketStatusComponent = ({status}) => {
    const bgColor =status && getStatusColor(status?.toLowerCase()) 
    const textColor =status && getStatusTextColor(status?.toLowerCase())
  if (status) {
    return (
      <Box className="ticket__status__cell" sx={{ background: bgColor, color: textColor }}>
        <TooltipLabel text={status ? status : "-"} />
      </Box>
    )
  } else {
    return '--'
  }

}


export default TicketStatusComponent


