import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { MuiThemedButton } from 'components/common/MuiThemedComponents';
import { downloadFileFromAPI } from 'utils/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { fetchExport } from 'store/exportApi';
import { Menu, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GlobalExportButton = ({  heading, text, fileName, customExportButton, exportApiRequest, enableFilteredDataExportButton }) => {
    const [openDownloadConfimationPopup, setopenDownloadConfimationPopup] = React.useState(false);
    const [currentDownloadState, setCurrentDownloadState] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleDownloadClick = (event) => {
        if (exportApiRequest?.dataOf == "devicemapping" || exportApiRequest?.dataOf == "flappinglog") {
            setopenDownloadConfimationPopup(true);
            setCurrentDownloadState('all_data');
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };


    const handleOptionClick = (val) => {
      setCurrentDownloadState(val)
      setopenDownloadConfimationPopup(true)
    };

    const exportOptions = React.useMemo(() =>{
        return [
            {
                id: 1,
                isEnabled: enableFilteredDataExportButton,
                title: "Filtered Data",
                action: () =>{
                    handleOptionClick('current_view')
                }
            },
            {
                id: 2,
                isEnabled: true,
                title: "All Data",
                action: () => {                
                    handleOptionClick('all_data')
                }
            }
        ]

    },[enableFilteredDataExportButton])
    
    return (
        <>
            <div onClick={(event) => { handleDownloadClick(event) }}>
                {
                    customExportButton ? customExportButton :
                        <MuiThemedButton className="import_btn btn" variant="contained" size="small" color="success">
                            <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" className="export_btn" />
                        </MuiThemedButton>
                }
            </div>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{padding: 0}}
            >
                {
                    exportOptions && exportOptions?.length > 0 && exportOptions?.map((item, index) => {
                        if(item?.isEnabled) {
                            return (
                                <MenuItem key={index} onClick={(e) => { e?.stopPropagation(); item?.action() }} disableRipple>
                                    {item?.title}
                                </MenuItem>
                            )
                        }
 
                    })
                }
            </Menu>


            <React.Suspense fallback={<div></div>}>
                {openDownloadConfimationPopup &&
                    <DownloadConfirmationPopup
                        open={openDownloadConfimationPopup}
                        close={() => { setopenDownloadConfimationPopup(false); handleClose() }}
                        heading={heading}
                        text={text}
                        fileName={fileName}
                        exportApiRequest={exportApiRequest}
                        currentDownloadState={currentDownloadState}
                    />
                }
            </React.Suspense>
        </>
    )
}


const DownloadConfirmationPopup = ({ open, close, heading, text, fileName , exportApiRequest, currentDownloadState}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const onClickOfDownload = async () => {
        setIsLoading(true);
        try{
            let request = {  
                currentState:currentDownloadState,
                ...exportApiRequest
            }
            let res = await dispatch(fetchExport(request)).unwrap();
            if (res) {
                await downloadFileFromAPI(res, fileName)
            }
        } catch(err) {
            console.error(err);
            toast.error("Oops! Something went wrong");         
        } finally {
            setIsLoading(false);
            close()

        }
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Download ${heading}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiThemedButton showLoader={isLoading} onClick={() => onClickOfDownload()} variant="contained" size="small" color="success">Download</MuiThemedButton>
                    <MuiThemedButton onClick={close} variant="contained" size="small" >Close</MuiThemedButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GlobalExportButton
