import PageLoader from 'components/common/PageLoader';
import NotFound from 'components/pages/NotFound/NotFound';
import { useAuth } from 'hooks/useAuth';
import Layout from 'layout/MainLayout';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PrivateRoutes from 'routes/PrivateRoutes';
import AccessDenied from 'components/pages/accessdenied/AccessDenied';
import ChangelogDetails from 'components/pages/changelogs/ChangelogDetails';
import React, { Suspense, lazy } from 'react';
import ChangeLogs from 'components/pages/changelogs/ChangeLogs';
import FlappingLogs from 'components/pages/eventLogs/FlappingLogs';
import NewBinding from 'components/pages/binding/newBinding';
import TicketsList from 'components/pages/tickets/TicketsList';
import WebMonitor from 'components/pages/webMonitor/webmonitor';
import ContactDetails from 'components/pages/tenants/contactDetails';
import TicketDetail from 'components/pages/tickets/TicketDetail';
import LinkMapping from 'components/pages/link/LinkMapping';
const Dashboard = lazy(() => import('components/pages/dashboard/Dashboard'));
const TenantsList = lazy(() => import('components/pages/tenants/TenantsList'));
const ChangeLog = lazy(() => import('components/pages/changelog/ChangeLog'));
const SiteList = lazy(() => import('components/pages/sites/SiteList'));
// const Binding = lazy(() => import('components/pages/binding/Binding'));
const DevicesList = lazy(() => import('components/pages/devices/Main'));
const InventorySync = lazy(() => import('components/pages/devices/sync/Sync'));
const Service = lazy(() => import('components/pages/service/Service'));
const LinkMonitoring = lazy(() => import('components/pages/link/LinkMonitoring'));
const CircuitLinkMonitoring = lazy(() => import ('components/pages/circuitLink/CircuitLinkMonitoring'));
const CreateSite = lazy(() => import('components/component/createSite/CreateSite'));
const StepperTenants = lazy(() => import('components/component/createTenants/StepperTenants'));
const Settings = lazy(() => import('components/pages/settings/Settings'));
const UserManagement = lazy(() => import('components/pages/usermanagement/UserManagement'));
const EmailNotifications = lazy(() => import('components/pages/emailNotifications/EmailNotificationsList'));

const GuestRoutes = () => {
    const auth = useAuth();

    return (
        <Router>
            <Suspense fallback={<PageLoader />}>
                {auth.isLoading ?
                    <PageLoader /> :
                    <Routes>
                        <Route element={<PageLoader />} path="/" />
                        <Route element={<Layout> <PrivateRoutes /> </Layout>}>
                            <Route element={<Dashboard />} path="/nf-status" />
                            <Route element={<Dashboard />} path="/nf-status/dashboard" />
                            <Route element={<TenantsList />} path="/nf-status/tenants" />
                            <Route element={<ChangeLog />} path="/nf-status/tenants/changelog" />
                            <Route element={<ContactDetails />} path="/nf-status/tenants/contact" />
                            <Route element={<SiteList />} path="/nf-status/site" />
                            <Route element={<ChangeLog />} path="/nf-status/site/changelog" />
                            <Route element={<NewBinding/>} path="/nf-status/binding" />
                            <Route element={<DevicesList />} path="/nf-status/inventory" />
                            <Route element={<ChangeLog />} path="/nf-status/inventory/changelog" />
                            <Route element={<InventorySync />} path="/nf-status/sync-inventory" />
                            <Route element={<Service />} path="/nf-status/service" />
                            <Route element={<LinkMonitoring />} path="/nf-status/link-monitoring" />
                            <Route element ={<CircuitLinkMonitoring/>} path='/nf-status/circuit-link-monitoring'/>
                            <Route element={<CreateSite />} path="/nf-status/site/add" />
                            <Route element={<ChangelogDetails />} path="/nf-status/change-logs/changelogs-details" />
                            <Route element={<StepperTenants />} path="/nf-status/tenants/add" />
                            <Route element={<StepperTenants />} path="/nf-status/tenants/edit" />
                            <Route element={<Settings />} path="/nf-status/settings" />
                            <Route element={<ChangeLogs />} path="/nf-status/change-logs" />
                            <Route element={<UserManagement />} path="/nf-status/user-management" />
                            <Route element={<FlappingLogs/>} path="/nf-status/flapping-logs" />
                            <Route element={<TicketsList/>} path="/nf-status/tickets" />
                            <Route element={<TicketDetail/>} path="/nf-status/ticket" />
                            <Route element={<WebMonitor/>} path="/nf-status/web-monitor" />
                            <Route element ={<LinkMapping/>} path='/nf-status/link-monitoring/link-mapping'/>
                            <Route element={<EmailNotifications/>} path="/nf-status/manage-notifications" />
                            
                        </Route>
                        <Route path="/nf-status/not-found" element={<NotFound />} />
                        <Route path="/nf-status/access-denied" element={<AccessDenied />} />
                        <Route path="*" element={<Navigate to="/nf-status/not-found" replace />} />
                    </Routes>
                }
            </Suspense>
        </Router>
    );
};

export default GuestRoutes;
