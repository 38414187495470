import React from 'react';

const StatusComponent = ({status, ...rest}) => {

    const getStatusClass = (status) => {
        switch (status?.toLowerCase()) {
          case "up":
          case "active":
            return "status status_active";
          case "down":
            return "status status_down";
          case "degraded":
            return "status status_degraded";
          case "disabled":
          case "maint":
            return "status status_inactive";
          case "n/a":
            return "status status_na";
          case null:
            return "status status_null";
          default:
            return "";
        }
      };

    return (
        <span
        {...rest}
		className={getStatusClass(status)}
	>
		{status ? status : "--"}
	</span>
    );
};

export default StatusComponent;
