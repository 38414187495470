import { Box, Typography } from '@mui/material'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import moment from 'moment-timezone'
import React from 'react'

const EmailCard = ({ email, handleEmailBodyPopup }) => {

  const emailData = [
    {
      title: 'Subject',
      valueKey: 'Subject',
      type: 'text'
    },
    {
      title: 'From',
      valueKey: 'FromAddress',
      type: 'text'
    },
    {
      title: 'To',
      valueKey: 'ToAddress',
      type: 'text'
    },
    {
      title: 'Date & Time',
      valueKey: 'MessageDate',
      type: 'date'
    },

  ]
  return (
    <Box className='ticket__sidebar__card' sx={{ alignItems: 'flex-start !important', flexDirection: 'column', overflow: 'hidden', margin: "12px 0px !important" }}>

      {emailData && emailData?.map((detail, index) => {
        return (
          <Typography component="p" key={"detail" + index} sx={{
            display: 'flex',
            width: '100%',
            padding: "4px 0px"
          }} >
            <Typography sx={{ width: "30%", whiteSpace: "nowrap", marginRight: "8px" }} component={"span"} className='ticket__sidebar__card--header'>
              {detail?.title}:
            </Typography>

            <Typography sx={{ width: "70%"}} component='span' className={`${detail?.valueKey === "Subject" ? 'ticket__sidebar__card--value--subject' : ""} ticket__sidebar__card--value'`} onClick={(e) => {
              e?.stopPropagation(); detail?.valueKey === "Subject" && handleEmailBodyPopup(true, email)
            }}>
              <TooltipLabel removeDataTag={true} fontStyle={{ fontSize: "14px" }} text={detail?.type === 'date' ? moment(new Date(email[detail?.valueKey])).format('LLL') : email[detail?.valueKey]} />
            </Typography>
          </Typography>
        )
      })}
    </Box>
  )
}

export default EmailCard
